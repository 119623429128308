import React, { useState, useEffect } from 'react';
import { getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import app from './firebase';
import AddEvent from './AddEvent';
import AddInvite from './AddInvite';
import AddTicket from "./AddTicket";

const Admin = () => {
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);  // Add isAdmin state
    const auth = getAuth();

    const checkIsAdmin = async (result) => {
        const userId = result?.user?.uid || result?.uid;
        if(!userId) {
            return;
        }
        const firestore = getFirestore(app);
        const docRef = doc(firestore, "users", userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const userData = docSnap.data();
            if (userData.isAdmin) {
                console.log("User is an admin");
                setIsAdmin(true);  // Set isAdmin state to true
            } else {
                console.log("User is not an admin");
                setIsAdmin(false); // Set isAdmin state to false
            }
        } else {
            console.log("No such user!");
        }
    }

    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();

        signInWithPopup(auth, provider)
            .then(async (result) => {
                setUser(result.user);
                checkIsAdmin(result)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const signOutWithGoogle = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            console.log("Signed out");
            setUser(null);
            setIsAdmin(false);
        }).catch((error) => {
            // An error happened.
            console.error("Error signing out", error);
        });
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, user => {
            setUser(user);
            checkIsAdmin(user);
        });

        return () => unsubscribe();
    }, [auth]);

    if (!user) {
        return (
            <button style={{margin: "30px"}} onClick={signInWithGoogle}>Sign in with Google</button>
        );
    }

    return (
        <div style={{margin: "30px"}}>
            <button onClick={signOutWithGoogle}>Logout</button>
            {isAdmin ?
                <>
                    {/*<AddInvite/>*/}
                    {/*<AddTicket></AddTicket>*/}
                    <AddEvent />
                </>
               : <div style={{color:'white'}}>You are not an admin</div>}
        </div>
    );
};

export default Admin;
