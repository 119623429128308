import React, {useEffect, useState} from 'react';
import {addDoc, collection, getDocs, getFirestore} from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import Resizer from 'react-image-file-resizer';
import app from './firebase';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './AddEvent.css';

const AddEvent = () => {
    const [name, setName] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [address, setAddress] = useState('');
    const [locationName, setLocationName] = useState('');
    const [organizer, setOrganizer] = useState('');
    const [about, setAbout] = useState('');
    const [ticketsWebsite, setTicketsWebsite] = useState('');
    const [ticketsWebsiteLabel, setTicketsWebsiteLabel] = useState('');
    const [errors, setErrors] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);
    const [topic, setTopic] = useState('');
    const [topics, setTopics] = useState([]);


    const uploadImage = async (imageFile) => {
        const storage = getStorage(app); // Get a reference to the storage service
        const storageRef = ref(storage, `eventImages/${imageFile.name}`); // Create a storage reference from our storage service

        // Create the file metadata
        const metadata = {
            contentType: imageFile.type
        };

        const uploadTask = uploadBytesResumable(storageRef, imageFile, metadata);

        // Listen for state changes, errors, and completion of the upload.
        return new Promise((resolve, reject) => {
            uploadTask.on('state_changed',
                (snapshot) => {
                    // You could handle progress updates here (upload is running)
                },
                (error) => {
                    // Handle unsuccessful uploads
                    reject(error);
                },
                () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        resolve(downloadURL);
                    });
                }
            );
        });
    };

    const getEvent = (imageRef) => {
        let event = {
            name,
            about,
            startDate,
            endDate,
            image_ref: imageRef,
            location: {
                address,
                label: locationName
            },
            organizer,
            topic
        };

        if (ticketsWebsite.trim() && ticketsWebsiteLabel.trim()) {
            event.ticketsLink = {
                label: ticketsWebsiteLabel,
                link: ticketsWebsite
            };
        }

        return event;
    };

    const validateFields = () => {
        let errors = {};

        if (!name.trim()) errors.name = "Name is required";
        if (name.trim().length > 40) errors.name = "Name cannot exceed 40 characters";
        if (!organizer.trim()) errors.organizer = "Organizer is required";
        if (!locationName.trim()) errors.locationName = "Location Name is required";
        if (!address.trim()) errors.address = "Address is required";
        if (!startDate) errors.startDate = "Start Date is required";
        if (!endDate) errors.endDate = "End Date is required";
        if (!about.trim()) errors.about = "About is required";
        if (endDate <= startDate) errors.endDate = "End date must be after start date";

        if (ticketsWebsite.trim() && !ticketsWebsiteLabel.trim()) errors.ticketsWebsiteLabel = "Website label is required when website link is provided";
        if (!ticketsWebsite.trim() && ticketsWebsiteLabel.trim()) errors.ticketsWebsite = "Website link is required when website label is provided";

        if (!selectedImage) errors.selectedImage = "Image is required";
        if (selectedImage && !['image/jpeg', 'image/png'].includes(selectedImage.type)) errors.selectedImage = "Image must be a .jpg or .png file";
        if (!topic) errors.topic = "Topic is required";

        setErrors(errors);

        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validateFields()) return;

        let imageRef = '';
        if (selectedImage) {
            try {
                imageRef = await uploadImage(selectedImage);
            } catch (error) {
                console.error("Error uploading image: ", error);
                return;
            }
        }

        const event = getEvent(imageRef);
        try {
            await addDoc(collection(getFirestore(app), 'events'), event);
            clearFields();
        } catch (e) {
            console.log(e);
        }
    };

    function calculateQuality(initialSizeInBytes) {
        const desiredSizeInKB = 150;
        const desiredSizeInBytes = desiredSizeInKB * 1024;
        let quality = 100;

        if (initialSizeInBytes > desiredSizeInBytes) {
            // Calculate the percentage of the desired size in relation to the initial size
            const ratio = desiredSizeInBytes / initialSizeInBytes;

            // Convert the ratio to a quality value, considering that higher ratios mean better quality
            quality = Math.floor(ratio * 100);

            // Restrict the quality value to a range of 10-100
            quality = Math.max(30, Math.min(quality, 100));
        }

        return quality;
    }


    const resizeFile = (file) => new Promise(resolve => {
        Resizer.imageFileResizer(file, 2000, 2000, 'jpeg', calculateQuality(file?.size), 0,
            uri => {
                resolve(uri);
            },
            'file'
        );
    });

    const handleImageUpload = async (event) => {
        const imageFile = event.target.files[0];

        try {
            const image = await resizeFile(imageFile);
            setSelectedImage(image);
        } catch (error) {
            console.error("Error while compressing image", error);
        }
    };

    const clearFields = () => {
        setName('');
        setOrganizer('');
        setLocationName('');
        setAddress('');
        setStartDate(new Date());
        setEndDate(new Date());
        setTicketsWebsite('');
        setTicketsWebsiteLabel('');
        setAbout('');
    };

    useEffect(() => {
        const fetchTopics = async () => {
            const topicsCollection = collection(getFirestore(app), 'topics');
            const topicsSnapshot = await getDocs(topicsCollection);
            const topicsList = topicsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setTopics(topicsList);
        };

        fetchTopics();
    }, []);

    return (
        <div className="AddEvent">
            <label>Name:</label>
            <input type="text" value={name} onChange={e => { setName(e.target.value); setErrors(prev => ({ ...prev, name: null })); }} />
            {errors.name && <div className="error">{errors.name}</div>}
            <label>Organizer:</label>
            <input type="text" value={organizer} onChange={e => { setOrganizer(e.target.value); setErrors(prev => ({ ...prev, organizer: null })); }} />
            {errors.organizer && <div className="error">{errors.organizer}</div>}
            <label>Location Name:</label>
            <input type="text" value={locationName} onChange={e => { setLocationName(e.target.value); setErrors(prev => ({ ...prev, locationName: null })); }} />
            {errors.locationName && <div className="error">{errors.locationName}</div>}
            <label>Address:</label>
            <input type="text" value={address} onChange={e => { setAddress(e.target.value); setErrors(prev => ({ ...prev, address: null })); }} />
            {errors.address && <div className="error">{errors.address}</div>}
            <label>Start Date:</label>
            <DatePicker
                selected={startDate}
                onChange={(date) => { setStartDate(date); setErrors(prev => ({ ...prev, startDate: null })); }}
                showTimeSelect
                dateFormat="Pp"
            />
            {errors.startDate && <div className="error">{errors.startDate}</div>}
            <label>End Date:</label>
            <DatePicker
                selected={endDate}
                onChange={(date) => { setEndDate(date); setErrors(prev => ({ ...prev, endDate: null })); }}
                minDate={startDate}
                showTimeSelect
                dateFormat="Pp"
            />
            {errors.endDate && <div className="error">{errors.endDate}</div>}
            <label>Website Link (optional):</label>
            <input type="text" value={ticketsWebsite} onChange={e => setTicketsWebsite(e.target.value)} />
            {errors.ticketsWebsite && <div className="error">{errors.ticketsWebsite}</div>}
            <label>Website Label (optional):</label>
            <input type="text" value={ticketsWebsiteLabel} onChange={e => setTicketsWebsiteLabel(e.target.value)} />
            {errors.ticketsWebsiteLabel && <div className="error">{errors.ticketsWebsiteLabel}</div>}
            <label>Image Reference:</label>
            <label>{selectedImage?.name || 'no file selected...'}</label>
            <input type="file" onChange={handleImageUpload} />
            {errors.selectedImage && <div className="error">{errors.selectedImage}</div>}
            <label>Topic:</label>
            <select value={topic} onChange={e => setTopic(e.target.value)}>
                <option value="">Select a topic...</option>
                {topics.map(topic => (
                    <option key={topic?.id} value={topic?.id}>
                        {topic?.topicLabel}
                    </option>
                ))}
            </select>
            {errors.topic && <div className="error">{errors.topic}</div>}
            <label>About:</label>
            <textarea value={about} onChange={e => { setAbout(e.target.value); setErrors(prev => ({ ...prev, about: null })); }} style={{height: '200px'}}/>
            {errors.about && <div className="error">{errors.about}</div>}
            <button onClick={handleSubmit}>Add event</button>
        </div>
    );
};

export default AddEvent;
