import React from 'react';
import logo from "./images/logo_app_header.png";

const PrivacyPolicy = () => {
    return (
        <div className="page-container">
            <div style={{textAlign: 'center', marginBottom: '100px'}}>
                <img src={logo} alt="logo" className="logoName" />
            </div>

            <h2>Politica de confidentialitate</h2>

            <h3>
                1. DEFINIȚII ȘI TERMENI</h3>
            <p>În accepțiunea prezentei Politici prin următorii termeni se înțelege:
            </p>



            <p><strong>uNite:</strong> este denumirea comercială a UNITE TECHNOLOGY S.R.L., persoana juridica de naționalitate romana, având sediul social în București, sector 4, Strada Vatra Dornei, nr. 4, bl. E2, ap. 6, având număr de ordine în Registrul Comerțului J40/2818/26.02.2020, cod de înregistrare fiscală 42321162 care administrează Aplicatia mobila și Serviciul uNite.   </p>
            <p><strong>Aplicatie mobila::</strong> aplicatie mobila online găzduită the AppStore si GooglePlay denumita U.Nite și/sau la adresa/adresele web aflate în proprietatea Organizatorilor cu care uNite colaborează.</p>
            <p><strong>Serviciul uNite:</strong> (Serviciile): Set de funcționalități și servicii puse la dispoziție de uNite prin intermediul Aplicatiei mobile.</p>
            <p><strong>Organizator:</strong> persoană juridică sau fizică ce organizează evenimente (muzicale, culturale, artistice, sportive, conferințe, expoziții, etc.) care utilizează Serviciul uNite.</p>
            <p><strong>Eveniment:</strong>  manifestare muzicală, culturală, sportivă, artistică, conferință, expoziție etc. deschisă publicului, organizată în scopuri de relaxare, divertisment, agrement, culturale, științifice, la care accesul se face pe bază de bilet.</p>
            <p><strong>Cont:</strong> secțiunea din Aplicatia mobila formată dintr-o adresa de e-mail și o parolă care permite Utilizatorului / Cumpărătorului transmiterea Comenzii și care conține informații despre Utilizatorului/Cumpărător și istoricul Cumpărătorului în Aplicatie (Comenzi, Bilete etc). Utilizatorul este responsabil și se va asigura că toate informațiile introduse la crearea Contului sunt corecte, complete și actualizate.
            </p>
            <p><strong>Utilizator:</strong> orice persoană fizică care are vârsta peste 16 ani sau persoană juridică înregistrată pe Aplicatia mobila, care vizitează ori accesează Aplicatia mobila sau care dorește să utilizeze în orice mod sau utilizează efectiv Serviciul uNite sau care prin finalizarea procesului de creare a Contului, și-a dat acordul cu privire la clauzele specifice aplicatiei din secțiunea Termeni și Condiții pentru Utilizatori.
            </p>
            <p><strong>Cumpărător:</strong>  numit și Participant, poate fi orice persoană fizică care are vârsta peste 16 ani  sau orice entitate juridică care își face un Cont în Aplicatia mobila și care își exprima intenția de a obține bilet(e) pentru a avea acces la evenimentele publicate de Organizatori și efectuează o Comandă în acest sens.

            </p>
            <p><strong>Comandă:</strong> un document electronic ce intervine ca forma de comunicare între Cumpărător / Utilizator și Organizator prin care Cumpărătorul / Utilizator transmite Organizatorului, prin intermediul Aplicatiei mobile.</p>
            <p><strong>Bilet:</strong> cod alfanumeric unic transpus în format grafic (cod de bare/ QR) livrat și stocat în format electronic sau tipărit și care îi asigură deținătorului accesul la evenimentul sau la serviciile descrise de Organizator.
            </p>
            <p><strong>Rețeaua uNite:</strong> Rețeaua de distribuție a biletelor a căror vanzare este intermediată de uNite și include Aplicatia mobila care poate fi modificata de uNite propriei sale opțiuni discreționare.
            </p>

            <h3>2. TERMENI GENERALI</h3>
            <p>uNite este o Aplicatie mobila de vânzare și cumpărare de bilete pentru evenimente. Ori de câte ori folosim termenul de „Organizator” în prezenta Politică, ne referim la organizatorii de evenimente care folosesc Serviciul pentru management acces și vanzare bilete la evenimente. </p>
            <p>Componentele Serviciilor includ, dar nu se limitează la, Aplicatia mobila pentru cumparare, vanzare bilete, pentru scanare coduri, pentru organizari evenimente, pentru platformele iOS și Android.</p>
            <p>Pentru noi, protecția datelor cu caracter personal are o foarte mare importanță. De aceea, ne angajăm să acordăm o atenție sporită modului în care vă prelucrăm datele cu caracter personal (în continuare „date”).
            </p>
            <p>Această Politică de confidențialitate este concepută pentru a vă oferi informații complete cu privire la modul de prelucrare a datelor de către compania noastră („Noi” sau „uNite”), în urma accesării și utilizării Aplicatiei mobile </p>
            <h4>Neacceptarea Politicii de Confidentialitate, ori a oricărei prevederi din acestea, atrage obligația persoanei respective de a înceta de îndată accesarea Aplicatiei mobile. Accesarea sau vizitarea în continuare a Aplicatie mobile, a oricărei pagini din acesta și/sau utilizarea Serviciului uNite, precum și a oricărei componente al acestuia constituie o acceptare în întregime și necondiționată a Politicii de Confidentialitate și a oricărei prevederi din acestea.</h4>

            <h3>3. CINE ESTE RESPONSABIL PENTRU PRELUCRAREA DATELOR ȘI PE CINE PUTEȚI CONTACTA?</h3>
            <p><strong>UNITE TECHNOLOGY S.R.L.</strong> <br/>
                23/4711/20.07.2023; CIF 48520237 <br/>
                Cantonului 14N, Corbeanca, România<br/>
                e-mail: tcomboeanu@gmail.com
            </p>

            <h3>4. CE DATE PRELUCRĂM?</h3>
            <h4>4.1 Toți Utilizatorii</h4>

            <p>Când interacționați cu Aplicatia mobila uNite, este posibil să colectăm date. Uneori vom face asta în nume propriu iar în alte cazuri vom colecta date în numele Organizatorului care utilizează Serviciile noastre. Aceasta este o distincție importantă din punct de vedere al protecției datelor, și va fi explicată în detaliu la secțiunea 9., de mai jos.
            </p>
            <p>Informații pe care ni le furnizați: Prelucrăm datele pe care ni le furnizați în mod voluntar atunci când creați un cont de Utilizator pe aplicatia nostru și atunci când ne contactați pentru a ne solicita informații.
            </p>
            <p>Informații pe care le colectăm în mod automat: Prelucrăm anumite date tehnice trimise către noi de computerul, dispozitivul sau telefonul prin care accesați Serviciile. Datele colectate în mod automat includ identificatorul unic asociat cu dispozitivul dumneavoastră (e.g., adresa IP), statistici cu privire la modul de utilizare a Serviciilor și date colectate prin cookie-uri.

            </p>

            <h4>4.2 Organizatori</h4>
            <p>Informații pe care ni le furnizați: Pentru a vă putea furniza Serviciile și pentru a putea asigura efectuarea plăților, vă vom solicita informații precum numele companiei și informațiile de înregistrare, contul bancar și detaliile de contact ale reprezentantului legal (i.e., nume, număr de telefon, e-mail), dintre care unele pot fi considerate date cu caracter personal.
            </p>
            <p>
                Informații obținute din alte surse: Am putea colecta sau primi date personale de la terți, precum website-uri, banca dumneavoastră, partenerii dumneavoastră de procesare a plăților sau agenții de credit.

            </p>
            <p>Informații pe care le colectăm în mod automat: Prelucrăm anumite date tehnice trimise către noi de computerul, dispozitivul sau telefonul prin care accesați Serviciile. Datele colectate în mod automat includ identificatorul unic asociat cu dispozitivul dumneavoastră (e.g., adresa IP), statistici cu privire la modul de utilizare a Serviciilor și date colectate prin cookie-uri.

            </p>

            <h4>4.3 Utilizatorii</h4>
            <p>Vom colecta datele dumneavoastră, uneori pentru scopurile noastre, iar în alte cazuri în numele unui Organizator (vedeți secțiunea 9., de mai jos).

            </p>
            <p>Informații pe care ni le furnizați prin intermediul Aplicatiei mobile: Vom colecta datele necesare creării unui cont de utilizator (i.e., nume, prenume, e-mail, număr de telefon, adresa).
            </p>
            <p>Informații pe care le colectăm în mod automat: Vom colecta în mod automat datele referitoare la locația dumneavoastră (i.e., oraș, județ, țară).
            </p>
            <p>În plus, Organizatorii pot crea pagini de evenimente, care să conțină formulare customizate de colectare a datelor, prin care aceștia pot colecta orice alte informații din partea dumneavoastră, necesare pentru organizarea evenimentelor listate în cadrul Serviciilor noastre. uNite nu deține controlul asupra procesului de înregistrare și nici asupra datelor colectate de Organizatori. Datele colectate în numele Organizatorilor sunt făcute disponibile acestora în conformitate cu secțiunea „Cine primește datele dumneavoastră?”, de mai jos.

            </p>
            <p>Informații obținute din alte surse: Este posibil să colectăm sau să primim date din partea unor terți, precum Organizatorii, alți consumatori, platformele de social media sau alți terți ale căror aplicații sunt integrate în Aplicatia mobila, banca dumneavoastră sau furnizorii noștri de servicii de plată. </p>

            <h3>5. CUM FOLOSIM DATELE DUMNEAVOASTRĂ?</h3>
            <p>Colectăm și utilizăm datele dumneavoastră în conformitate cu prezenta Politică. Am putea folosi datele dumneavoastră după cum urmează:</p>

            <h4>5.1 Scopuri specifice</h4>
            <p>Dacă ne furnizați date pentru un anume scop, vom folosi respectivele date în legătură cu respectivul scop. Spre exemplu, dacă ne contactați prin e-mail, vom folosi datele pe care ni le furnizați pentru a vă răspunde la solicitări, utilizând în acest scop adresa de pe care ne-ați contactat.</p>

            <h4>5.2 Acces și Utilizare</h4>
            <p>Dacă ne furnizați date pentru a accesa Serviciile noastre, vom folosi datele pentru a vă oferi acces și pentru a analiza modul în care folosiți Serviciile. Spre exemplu, folosim datele colectate în mod automat (i.e., oraș, județ, țară) pentru a vă putea oferi conținutul de pe Aplicatia mobila în limba utilizată pe teritoriul în care sunteți situat.</p>

            <h4>5.3 Scopuri administrative interne</h4>
            <p>Datele dumneavoastră pot fi utilizate pentru scopuri administrative interne, printre care, îmbunătățirea conținutului, calității și funcționalității Serviciilor, pentru înțelegerea Utilizatorilor noștri, pentru identificarea și prevenirea fraudei, pentru punerea în aplicare a Termenilor și Condițiilor, pentru administrarea contului dumneavoastră și, în general, pentru administrarea Serviciilor.
            </p>

            <h4>5.4 Comunicări de marketing</h4>
            <p>În conformitate cu preferințele dumneavoastră referitoare la marketing, vă putem folosi datele pentru a vă transmite informații în legătură cu servicii sau evenimente care v-ar putea interesa și pentru a publica conținut sau reclame în cadrul sau în afara Serviciilor. Vedeți secțiunea „Renunțarea la comunicări electronice”, de mai jos, pentru a afla cum vă puteți exprima preferințele în legătură cu comunicările de marketing.
            </p>

            <h4>5.5 E-mailuri de la Organizatori
            </h4>
            <p>uNite dă Organizatorilor posibilitatea de a contacta consumatorii în legătură cu evenimentele lor, astfel că ați putea primi e-mailuri din partea sistemului nostru, transmise de către Organizatori sau de către Noi în numele acestora. Atunci când achiziționați un bilet în cadrul Serviciilor, Organizatorii evenimentului la care participați vor avea acces la numele, prenumele și adresa dumneavoastră de e-mail. Cu toate astea, Organizatorii pot de asemenea să importe adrese de e-mail pe care le-au obținut din surse terțe, și pot transmite comunicări prin intermediul Serviciilor către respectivele adrese. Noi vom livra respectivele comunicări în numele Organizatorilor. Organizatorii și nu uNite vor fi responsabili pentru transmiterea respectivelor e-mailuri. Vedeți secțiunea „Renunțarea la comunicări electronice” pentru a afla cum vă puteți exprima preferințele în legătură cu comunicările inițiate de Organizatori. </p>

            <h4>
                5.6 Alte scopuri</h4>
            <p>Nu vom folosi datele dumneavoastră în alte scopuri. Cu toate acestea, dacă vom intenționa să folosim datele în moduri incompatibile cu prezenta Politică, veți fi informați înainte sau la momentul colectării datelor, ori vă vom obține consimțământul în acest sens ulterior colectării dar înaintea utilizării respectivelor date.
            </p>

            <h4>5.7 Date agregate</h4>
            <p>În vederea îmbunătățirii Serviciilor, am putea analiza datele colectate pentru a studia demografia, interesele și comportamentele Utilizatorilor noștri. Aceste analize sunt făcute cu privire la date agregate, care nu pot conduce la identificarea dumneavoastră, astfel că nu vor fi considerate date personale în sensul prezentei Politici.
            </p>

            <h3>6. CINE PRIMEȘTE DATELE DUMNEAVOASTRĂ?
            </h3>
            <p>Nu vindem datele dumneavoastră către terți și către furnizori de servicii de marketing.<br/><br/>

                Datele vor fi prelucrate și utilizate numai în măsura în care acest lucru este necesar pentru îndeplinirea scopurilor menționate mai sus. Poate fi necesar, de la caz la caz, ca datele să fie transferate unor terți pentru a atinge respectivele scopuri.
            </p>
            <p>Cu toate acestea, există circumstanțe în care am putea face disponibile, transfera sau împărtăși datele dumneavoastră cu anumiți terți fără ca dumneavoastră să fiți informați cu fiecare prilej, după cum vom detalia în cele ce urmează.</p>

           <h4>6.1 Vânzarea companiei, a Serviciilor sau a unor active</h4>
            <p>Pe măsură ce ne dezvoltăm, am putea vinde sau cumpăra companii și active. În cazul unei vânzări, reorganizări, fuziuni, dizolvări sau în alte situații similare, datele dumneavoastră ar putea face parte din activele transferate. Orice succesor în drepturi sau cumpărător al uNite va continua să aibă dreptul să utilizeze datele dumneavoastră sau alte informații în conformitate cu prezenta Politică.
            </p>

            <h4>6.2 Agenți, consultanți sau furnizori de servicii
            </h4>
            <p>Dacă autorizăm o persoană împuternicită, vom rămâne pe deplin responsabili pentru protejarea datelor dumneavoastră. Persoanele împuternicite de Noi sau alți terți vor primi datele dumneavoastră doar în cazul în care acestea sunt necesare pentru prestarea serviciilor solicitate. Acestea pot fi, de exemplu:
            </p>
            <ul>
                <li>furnizorul serviciilor de plată;
                </li>
                <li>furnizorii serviciilor de e-mail;
                </li>
                <li>furnizorul serviciilor de găzduire și stocare a datelor;
                </li>
                <li>furnizorul serviciilor de localizare;
                </li>
                <li>furnizorul programului de facturare;
                </li>
                <li>furnizorii serviciilor de analiză a comportamentului Utilizatorilor;
                </li>
                <li>furnizorii serviciilor de publicitate;
                </li>
                <li>furnizorii serviciilor de administrare a fluxurilor de lucru din cadrul uNite;
                </li>
                <li>furnizorii serviciilor de relații cu clienții.
                </li>
            </ul>

            <h4>6.3 Organizatori</h4>
            <p>Când cumpărați bilete la un eveniment prin intermediul Serviciilor, datele pe care ni le furnizați în acest scop vor fi făcute disponibile Organizatorului respectivului eveniment. Nu suntem responsabili cu privire la modul în care Organizatorii folosesc datele dumneavoastră personale. Drept urmare, este important să verificați politicile Organizatorilor privind protecția datelor înaintea furnizării datelor dumneavoastră personale în vederea participării la eveniment.
            </p>

            <h4>6.4 Google sau alți terți</h4>
            <p>Vă puteți conecta la contul dumneavoastră uNite prin intermediul unor terți precum Google, caz în care am putea colecta, utiliza, dezvălui, transfera sau stoca informații referitoare la contul dumneavoastră înregistrat la aceste entități, în conformitate cu prezenta Politică. De exemplu, dacă vă conectați prin intermediul Google, am putea stoca numele de utilizator, adresa de e-mail, locația, lista prietenilor și poza de profil pentru a vă furniza anumite funcționalități în cadrul Serviciilor, precum recomandarea evenimentelor la care vor participa prietenii dumneavoastră.
            </p>

            <h4>6.5 Obligații legale</h4>
            <p>uNite poate fi legal obligată să divulge datele dumneavoastră în cazul în care acest lucru este necesar pentru stabilirea, exercitarea sau apărarea drepturilor Noastre în fața autorităților competente.</p>

           <h3>7. CUM STOCĂM DATELE DUMNEAVOASTRĂ?</h3>
            <p>uNite a luat toate măsurile necesare pentru asigurarea protecției datelor dumneavoastră împotriva pierderii, furtului, folosirii în mod neadecvat sau neautorizat, precum și împotriva accesării, dezvăluirii, modificării sau distrugerii accidentale. Cu toate acestea, nicio rețea, server, bază de date sau transmisiune e-mail nu este perfect sigură sau lipsită de eroare. Prin urmare, vă rugăm să vă gândiți cu atenție la informațiile pe care ni le transmiteți electronic, înainte să o faceți.
            </p>

            <h3>8. CAT PĂSTRĂM DATELE DUMNEAVOASTRĂ?
            </h3>

            <p>Vom păstra datele dumneavoastră atât timp cât sunteți un Utilizator înregistrat al Serviciilor. Vă puteți închide contul contactându-ne la adresa ciprian.constantin91@gmail.com.
            </p>
            <p>Cu toate acestea, vom păstra datele dumneavoastră în măsura cerută sau permisă de lege. De exemplu, vom continua să vă prelucrăm datele cu respectarea principiului minimizării și al limitării la scop, în cazul în care suntem obligați de o prevedere legală (e.g. păstrarea documentelor, înregistrărilor și registrelor contabile, pentru o perioadă de 5 sau 10 ani) sau dacă avem un interes legitim (e.g. în scopuri de audit sau atunci când respectivele informații sunt necesare pentru desfășurarea de investigații sau pentru acțiuni în justiție, pentru o perioadă de 3 ani).

            </p>
            <p>Vom asigura ștergerea datelor dumneavoastră în momentul în care stocarea acestora nu mai este susținută de nici unul din temeiurile de prelucrare menționate mai sus.
            </p>

            <h3>9. ALEGERILE DUMNEAVOASTRĂ
            </h3>
            <h4>9.1 Cookie-uri
            </h4>
            <p>Vă rugăm să consultați Politica noastră privind cookie-urile pentru mai multe informații.</p>

            <h4>9.2 Limitarea datelor
            </h4>
            <p>Nu puteți naviga Aplicatia mobila fără să ne furnizați date personale (exceptând informațiile care sunt colectate în mod automat, în măsura în care acestea pot fi calificate drept date personale potrivit legilor aplicabile). Dacă alegeți să nu ne furnizați datele necesare, nu veți putea beneficia de Serviciile noastre.
            </p>

            <h3>9.3 Renunțarea la comunicări electronice
            </h3>

            <h4>Marketing</h4>
            <p>În conformitate cu preferințele dumneavoastră, vă putem trimite mesaje cu privire la Serviciile noastre sau cu privire la evenimentele din cadrul Serviciilor, în măsura în care ați cumpărat un bilet prin intermediul Serviciilor noastre. Puteți alege să nu mai primiți astfel de comunicări prin accesarea link-ului „Nu doresc să mai primesc comunicări”, de la finalul fiecărui mesaj primit de la Noi. </p>
          <h4>Comunicări inițiate de Organizatori</h4>
            <p>Organizatorii pot trimite e-mailuri prin intermediul sistemelor noastre către adresele din bazele de date ale acestora, precum și către Utilizatorii care au participat la evenimentele organizate de către aceștia în trecut. Cu toate că aceste comunicări sunt transmise prin sistemul nostru, uNite nu determină conținutul sau recipienții acestora. Organizatorii sunt obligați să utilizeze uneltele noastre de e-mail numai în conformitate cu legile aplicabile. uNite va insera un link prin care utilizatorii se refuze primirea unor comunicări din partea unui Organizator la finalul fiecărui mesaj.
            </p>
            <h4>Comunicări legate de tranzacții
            </h4>
            <p>Anumite comunicări electronice din partea uNite vor avea loc în urma solicitărilor dumneavoastră. Spre exemplu, dacă achiziționați un bilet, acesta vă va fi transmis in portofelul electronic din Aplicatia mobila trimis de către Noi în numele Organizatorului. Indiferent de opțiunile dumneavoastră referitoare la transmiterea de comunicații de marketing, veți primi întotdeauna e-mailuri legate de tranzacțiile încheiate. Puteți opri aceste comunicări contactându-ne la adresa ciprian.constantin91@gmail.com, însă nu vă recomandăm să o faceți decât în situația în care intenționați să nu mai utilizați Serviciile. </p>

            <h3>10. EXCLUDERI</h3>
            <h4>10.1 Date pe care le furnizați terților</h4>
            <p>Această Politică nu se aplică datelor pe care le furnizați altor Utilizatori ai Serviciilor, inclusiv informațiilor pe care le furnizați Organizatorilor prin intermediul paginilor de evenimente, ori informațiilor pe care le faceți publice în afara Serviciilor.
            </p>
            <h4>10.2 Link-uri ale unor terți
            </h4>
            <p>Această Politică este aplicabilă doar Serviciilor. Serviciile pot conține link-uri către alte website-uri, care nu sunt operate ori controlate de Noi. Prezenta Politică nu se aplică acestora. Prezența link-urilor pe pagina noastră nu garantează verificarea de către Noi a respectivelor website-uri. Vă sugerăm să contactați direct deținătorii unor astfel de websiteuri pentru mai multe informații cu privire la politicile lor de protecție a datelor.
            </p>
            <h4>10.3 Date ale minorilor
            </h4>
            <p>Serviciile noastre nu sunt destinate minorilor. Drept urmare, nu colectăm în mod conștient date ale unor minori cu vârsta mai mică de 16 ani. Dacă aveți mai puțin de 16 ani, vă rugăm să nu ne transmiteți date cu caracter personal. Dacă ați aflat că un minor cu vârsta mai mică de 16 ani ne-a furnizat date personale prin intermediul Serviciilor, vă rugăm să ne contactați la adresa ciprian.constantin91@gmail.com, și vom lua toate măsurile ca datele să fie șterse din sistemele noastre.
            </p>
            <h4>10.4 Modificări ale Politicii
            </h4>

            <p>Vom posta o notificare pe Aplicatia mobila ori de câte ori Politica noastră va fi modificată substanțial.
            </p>

            <h3>11. uNite – OPERATOR SAU ÎMPUTERNICIT?</h3>
            <p>Legislația referitoare la protecția datelor face o distincție între entitățile care prelucrează date pentru scopuri proprii („operatori”) și cele care prelucrează date în numele altor organizații („împuterniciți”). Dacă aveți întrebări sau reclamații cu privire la modul în care sunt folosite datele dumneavoastră, acestea ar trebui să fie întotdeauna direcționate către operatorul relevant, întrucât acesta este principalul responsabil cu privire la protecția datelor dumneavoastră.</p>
            <p>În funcție de circumstanțele specifice, uNite poate avea calitatea de operator sau de împuternicit cu privire la datele dumneavoastră.
            </p>
            <p>De exemplu, vom fi operatori cu privire la datele pe care le colectăm atunci când creați un cont de Utilizator pe Aplicatia mobila.
            </p>
            <p>Cu toate acestea, vom avea calitatea de persoană împuternicită a Organizatorilor cu privire la datele pe care ni le furnizați în vederea achiziționării unui bilet prin intermediul Serviciilor noastre (e.g., pentru trimiterea confirmării, procesarea plăților etc.). În acest caz, uNite nu decide ce date să solicite prin formularele de înregistrare și nici nu este responsabilă pentru exactitatea datelor furnizate. Drept urmare, orice întrebare cu privire la datele dumneavoastră sau orice solicitare de exercitare a drepturilor dumneavoastră în conformitate cu legislația aplicabilă va trebui să fie adresată Organizatorului care are calitatea de operator, și nu uNite.

            </p>

            <h3>12. CARE SUNT DREPTURILE DUMNEAVOASTRĂ ȘI CUM LE PUTEȚI EXERCITA?
            </h3>
            <p>În conformitate cu Regulamentul (UE) 2016/679 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulație a acestor date, aveți, în anumite condiții, următoarele drepturi:
            </p>

            <ul>
                <li>De a accesa datele: puteți obține informații referitoare la prelucrarea datelor dumneavoastră cu caracter personal și o copie a acestor date cu caracter personal;</li>
                <li>De a rectifica datele: în cazul în care considerați că datele dumneavoastră cu caracter personal sunt inexacte sau incomplete, puteți solicita modificarea corespunzătoare a acestor date cu caracter personal;
                </li>
                <li>De a obține ștergerea datelor: puteți solicita ștergerea datelor dumneavoastră cu caracter personal, în măsura prevăzută de lege;
                </li>
                <li>De a restricționa prelucrarea: puteți solicita restricționarea procesării datelor dumneavoastră cu caracter personal;
                </li>
                <li>De a obiecta la prelucrare: puteți contesta prelucrarea datelor dumneavoastră cu caracter personal, din motive legate de situația dumneavoastră particulară. Aveți dreptul absolut de a vă opune prelucrării datelor dumneavoastră cu caracter personal în scopuri de marketing direct, aspect care include și profilarea în legătură cu respectivul marketing direct;
                </li>
                <li>De portabilitate a datelor: aveți dreptul să solicitați ca datele cu caracter personal pe care ni le-ați furnizat să vă fie returnate sau, dacă este posibil, să fie transferate către un terț.
                </li>
            </ul>
            <p>Deasemenea, cu privire la datele prelucrate în temeiul consimțământului dumneavoastră, aveți dreptul de a vă retrage consimțământul oricând, fără ca aceasta să afecteze legalitatea prelucrărilor efectuate anterior de către Noi.
            </p>
            <p>Puteți să vă exercitați drepturile enumerate mai sus printr-o cerere scrisă și datată, transmisă pe e-mail la adresa ciprian.constantin91@gmail.com, sau prin poștă, la adresa București, Sector 4, Strada Vatra Dornei nr. 4, bloc E2, ap. 6.
            </p>
            <p>Dacă ne trimiteți o cerere, datele cuprinse în aceasta vor fi accesibile numai acelor persoane care sunt autorizate şi implicate în procesul de soluționare.
            </p>
            <p>Există posibilitatea ca pentru determinarea identității dumneavoastră, să solicităm date suplimentare, fără ca prin aceasta să schimbăm scopul prelucrării inițiale a datelor.
            </p>
            <p>
                Pe lângă cele de mai sus, aveți dreptul și să depuneți o reclamație cu privire la prelucrarea datelor dumneavoastră la Autoritatea Națională de Supraveghere a Prelucrării Datelor cu Caracter Personal (ANSPDCP), B-dul. G-ral. Gheorghe Magheru nr. 28-30, Sector 1, cod poștal 010336 București, tel. 0318.059.211.</p>
            <p>Dacă aveți orice întrebare cu privire la prezenta Politică sau la felul în care gestionăm datele dumneavoastră, vă rugăm să ne contactați prin e-mail la adresa ciprian.constantin91@gmail.com
            </p>
        </div>
    );
};

export default PrivacyPolicy;
