import React from 'react';
import logo from "./images/logo_app_header.png";

const Terms = () => {
    return (
        <div className="page-container">
            <div style={{textAlign: 'center', marginBottom: '100px'}}>
                <img src={logo} alt="logo" className="logoName" />
            </div>

            <h2>Termeni generali</h2>
            <p>
                <strong>UNITE TECHNOLOGY S.R.L.</strong>, cu sediul în România, Bucuresti, sectorul 4, strada Vatra Dornei, nr. 4, camera 1, bloc E2, scara 1, etaj 1, ap. 6, având număr de ordine în Registrul Comerțului J40/2818/26.02.2020, cod de înregistrare fiscală RO42321162, denumită mai departe “uNite” deține și administrează rețeaua de vânzare bilete (“Rețeaua uNite”) și Serviciul uNite, o platformă de servicii de intermediere a vânzării de bilete / abonamente / vouchere și alte servicii conexe destinată organizatorilor de evenimente.
            </p>

            <p>
                Prezentul document cu valoare de contract, este reglementat de prevederile legii contractului la distanță (ordonanta 130 / 31 august 2000) și cea a comerțului electronic (legea 365 / 7 iunie 2002) și definește cadrul în care uNite este de acord să furnizeze servicii către orice persoană care vizitează ori accesează Aplicatia sau care dorește să utilizeze în orice mod sau utilizează efectiv Serviciul uNite (denumită în continuare 'Utilizator') și are valoarea unui contract la distanță între uNite și Utilizator, ale cărui date vor fi furnizate prin intermediul formularului de înregistrare Utilizatori din Aplicatie, astfel încât utilizarea serviciilor prestate de uNite presupune acceptarea și conformarea Utilizatorului la termenii și condițiile prezentului contract. Politica de Cookies și Politica de Confidențialitate sunt parte integrantă din acest set de Termeni și Condițiilor pentru Utilizatori.

            </p>

            <p>
                Orice Comandă confirmată de Utilizator, prin bifarea căsuței – "Am citit și am luat la cunoștință Termenii și Condițiile pentru Utilizatori", reprezintă o acceptare necondiționată din partea Utilizatorului a ofertei UNITE TECHNOLOGY S.R.L. și a termenilor și condițiilor conținute în documentul de mai jos, în condițiile stipulate de art. 9 din Legea comerțului electronic 365/2002. Bifarea căsuței reprezintă o semnătură electronică, în sensul art. 4 pct. 3 din Legea semnăturii electronice nr. 455/2001, având aceeași valoare cu o semnătura olografă. Prin "Comanda" se înțelege cererea adresată către uNite de un Utilizator prin care aceasta din urmă solicită serviciile uNite prin intermediul aplicatiei deținute de uNite.
            </p>

            <p>
                Termenii și condițiile prezentului contract trebuie acceptate înainte de utilizarea serviciilor uNite de pe domeniile sau de pe subdomeniile web, aflate în proprietatea și administrarea uNite.  Accesarea sau vizitarea în continuare a Aplicatiei, a oricărei pagini din acesta și/sau utilizarea Serviciului uNite, precum și a oricărei componente ale acestuia va exprimați acordul explicit și declarați ca ați citit și ați înțeles pe deplin conținutul contractului și constituie o acceptare în întregime și necondiționată a Termenilor și Condițiilor pentru Utilizatori și a oricărei prevederi din acestea. Neacceptarea Termenilor și Condițiilor pentru Utilizatori, ori a oricărei prevederi din acestea, atrage obligația persoanei respective de a înceta de îndată accesarea aplicatiei.
            </p>

            <h1>Definiții și interpretare</h1>
            <p>În accepțiunea “Termenilor si Conditiilor pentru Utilizatori” prin următorii termeni se înțelege:</p>
            <p>uNite: este denumirea comercială a UNITE TECHNOLOGY S.R.L., persoana juridica de naționalitate romana, având sediul social în Bucuresti, sectorul 4, strada Vatra Dornei, nr. 4, camera 1, bloc E2, scara 1, etaj 1, ap. 6, având număr de ordine în Registrul Comerțului J40/2818/26.02.2020, cod de înregistrare fiscală 42321162 care deține și administrează Rețeaua uNite și Serviciul uNite.
            </p>
            <p>Set de funcționalități și servicii puse la dispoziție de uNite prin intermediul Aplicatiei mobile.</p>
            <p>Rețeaua uNite: Rețeaua de distribuție a biletelor / abonamentelor a căror vânzare este intermediată de uNite în numele și pe seama Beneficiarului și include aplicatia și punctele de vânzare fizice, reprezentate prin Parteneri Autorizați, care pot fi modificate de uNite conform propriei sale opțiuni discreționare.</p>
            <p>
                Serviciul uNite: Set de funcționalități și servicii puse la dispoziție de uNite prin intermediul Aplicatiei mobile.</p>
            <p>Organizator: persoană juridică sau fizică ce organizează evenimente (muzicale, culturale, artistice, sportive, conferințe, expoziții, etc.) si care utilizează Serviciul uNite furnizat de uNite prin intermediul Retelei uNite.</p>
            <p>Utilizator: orice persoană fizică care are vârsta peste 16 ani sau persoană juridică înregistrată pe aplicatie, care vizitează ori accesează aplicatia sau care dorește să utilizeze în orice mod sau utilizează efectiv Serviciul uNite sau care prin finalizarea procesului de creare a Contului, și-a dat acordul în mod expres cu privire la clauzele specifice aplicatiei prin bifarea opțiunii “Sunt de accord cu Termeni și Condițiile pentru Utilizatori”.
            </p>
            <p>Cumpărător: Oricare Utilizator care își exprimă intenția de a obține bilete / abonamente pentru a avea acces la Evenimentele publicate de Organizator și efectuează o Comandă în acest sens în Rețeaua uNite.</p>
            <p>Comandă: un document electronic ce intervine ca forma de comunicare între Cumpărător și Organizator prin care Cumpărătorul transmite Organizatorului, prin intermediul aplicatiei, intenția sa de a achiziționa bilete de pe aplicatie.</p>
            <p>Bilet: cod alfanumeric unic transpus în format grafic (cod de bare/ QR) livrat și stocat în format electronic sau tipărit și care îi asigură deținătorului accesul la evenimentul sau la serviciile descrise de Organizator.</p>
            <p>Voucher de rezervare: numit și Voucher, este un document care atesta Cumpărătorului dreptul de a beneficia de un bilet în momentul preschimbarii unui voucher, în cadrul unui Eveniment, în termenii stabiliți de Organizator. </p>
            <p>Cont: secțiunea din Aplicatie formată dintr-o adresa de e-mail și o parolă care permite Utilizatorului / Cumpărătorului transmiterea Comenzii și care conține informații despre Utilizatorului/Cumpărător și istoricul Cumpărătorului în Aplicatie (Comenzi, Bilete etc). Utilizatorul este responsabil și se va asigura că toate informațiile introduse la crearea Contului sunt corecte, complete și actualizate.</p>
            <p>Comision de emitere bilete (Comision): reprezintă tariful perceput de uNite pentru serviciile de intermediere. În schimbul cumpărării și livrării biletelor la evenimente prin intermediul platformei uNite și poate fi diferit de la un eveniment la altul și poate fi procentual, fix sau mixt (%+ suma fixa).</p>
            <p>Taxa de livrare: reprezintă tariful perceput de uNite de la Organizator pentru metoda de livrare a biletelor aleasă de Utilizator în momentul plasării unei Comenzi.</p>
            <p>
                Taxa metodă de plată: reprezintă tariful perceput de uNite de la Organizator pentru metoda de plată aleasă de Utilizator în momentul plasării unei Comenzi.
            </p>
            <p>Licență: Sub rezerva Termenilor și Condițiilor acceptate explicit prin utilizarea Serviciului și acordul dat la crearea contului și respectarea de către dumneavoastră a acestora, suntem de acord să vă oferim acces la utilizarea aplicatiei. Accesul dvs. la aplicatie este furnizat în conformitate cu licența gratuită, limitata, non-exclusiva, și revocabila oferită de către UNITE TECHNOLOGY S.R.L.</p>

           <h1>3. Mod de funcționare</h1>
            <p>uNite ca și platformă de ticketing si de organizare evenimente oferă servicii de intermediere a vânzării de bilete între Organizatori și Utilizatori, poate deține biletele puse în vânzare pe aplicatie și/sau prin Rețeaua uNite. uNite colectează contravaloarea biletelor în numele și pe seama Organizatorului și emite biletele în numele Organizatorului, contra unui comision de intermediere, conform înțelegerilor contractuale între părți. </p>
            <p>Comenzile efectuate de Utilizatori pe uNite sunt confirmate printr-un e-mail/SMS de confirmare primit după finalizarea comenzii pe emailul/numărul de telefon furnizat la creare contului de Utilizator în aplicatie. În urma finalizării cu succes a unei comenzi online, Utilizatorul primește biletele achizitionate in aplicatie, sectiunea de bilete cumpărate, în format electronic (QR - code) cu care se prezinta la eveniment.</p>
            <p>
                Achiziția biletelor se va face în următoarele condiții:
                a) uNite oferă prin Serviciul uNite pus la dispoziția organizatorilor de evenimente vânzarea de bilete pentru o plajă largă de evenimente Utilizatorilor înregistrați pe aplicatie, în schimbul achitării acestor bilete.</p>
            <p>
                b) Biletele comandate sau achitate prin intermediul aplicatiei se vor găsi și în secțiunea “Biletele Mele” din cadrul aplicatiei.</p>
            <p>
                c) Nu se vor vinde bilete persoanelor care nu au efectuat în prealabil o comandă de bilete la respectivul eveniment prin intermediul aplicatiei.</p>
            <p>d) Nu se vor vinde bilete persoanelor care nu fac dovada plății sau care nu şi-au trecut corect sau complet datele în formularul de înscriere pe aplicatie.</p>
            <p>e) uNite își rezervă dreptul de a anula comenzile care nu conțin informații de identificare corecte pentru beneficiarii biletelor.</p>
            <p>f) Locurile selectate devin rezervate în momentul confirmării plății dumneavoastră.</p>
            <p>g) Organizatorul își rezervă dreptul de a modifica prețul biletelor fără un anunț public în prealabil.
                Sumele afișate pe aplicatie sunt sumele finale.</p>

            <h1>4. Prețul biletelor și taxe applicate</h1>
            <p>Când achiziționați bilete, de pe aplicatiei sau din Rețeaua uNite, este afișată valoarea nominală a biletului (denumit “preț bilet” sau “cost bilet”). Acest preț este stabilit în mod arbitrar de Organizator și vom deconta suma integral către Organizator, fără nicio deducere. </p>
            <p>Concomitent cu achiziția de bilete, de pe aplicatie sau din Rețeaua uNite, va fi calculat în mod automat valoarea comisionului pentru serviciile de intermediere pentru vânzarea de bilete (denumit “comision de emitere bilete” sau “taxa ticketing”). Comisionul de emitere bilete se calculează ca procent din valoarea nominală sau ca preț fix și este afișat separat de prețul biletelor încă din momentul selecției categoriei de bilete dorite și înainte de a plasa o comandă fermă de către Utilizator. Organizatorul pot alege în mod arbitrar cine va suporta comisionul de emitere bilete, fie Organizatorul, fie Utilizatorul, la nivel unei categorii de bilet.
            </p>
            <p>Comisionul de emitere de bilete este un standard al platformelor de ticketing, întâlnit la majoritatea furnizorilor, care asigură veniturile necesare pentru prestarea unui serviciu de ticketing securizat și include cheltuieli generale cum ar fi comisioane de procesare a cardului de debit / credit, TVA, servicii de suport pentru clienți, gestionarea apelurilor, administrarea, dezvoltarea și mentenanță a aplicatiei, costuri pentru server securizat, profit.
            </p>
            <p>Anumiți Organizatori cu care colaborăm aleg să adauge anumite taxe aferente biletelor (ex. taxa procesare plata, taxa livrare, taxa emitere bilet fizice etc dar fără a se limita la aceasta)  peste valoarea nominală a acestora, așa ca în anumite situații veți plăti o sumă mai mare decat cea comunicată pe anumite canale media, atât pe aplicatie cât și în punctele din Rețeaua uNite. Suma finală de plata va fi întotdeauna afișată înainte ca Utilizatorul sa efectueze o comanda ferma.
            </p>

            <h1>5. Utilizarea biletelor </h1>
            <p>Accesul la evenimente se va face doar după validarea la intrare a codului QR de pe bilet, cu ajutorul aplicației pusa la dispozitie de uNite sau portalului electronic de administrare al Serviciului uNite. Sunt acceptate următoarele modalități de prezentare a codului QR în vederea validării:</p>
            <ul>
                <li>
                    biletul electronic de pe telefonul mobil din secțiunea 'Biletele mele' de pe aplicatie.
                </li>
            </ul>
            <p>Este în datoria Utilizatorului de a se asigura de funcționarea terminalului mobil propriu şi de existența și validitatea biletului electronic, a accesului la internet, etc. Alternativ, dacă nu există posibilitatea prezentării cu biletul electronic, este de datoria Utilizatorului să printeze biletul electronic în întregime, astfel încât elementele de identificare să fie lizibile (cod QR, informații eveniment).
            </p>
            <p>Prin achiziționarea de bilete prin Serviciul uNite, Utilizatorii acceptă să respecte regulamentul de funcționare al locației unde se va desfășura evenimentul și/sau regulile de acces și desfășurare impuse de  organizatorul de eveniment, așa cum sunt ele stabilite în mod arbitrar de organizator. Organizatorul își rezervă dreptul de a nu permite accesul acelor persoane care nu respectă regulamentul de funcționare al locației unde se va desfășura evenimentul şi/sau regulile de acces și desfășurare prevăzute de organizator pentru desfășurarea evenimentului .
            </p>
            <p>uNite nu acordă niciun fel de despăgubire dacă accesul nu a fost permis din cauza încălcării oricărei prevederi al regulamentului de funcționare al locației unde se va desfășura evenimentul şi/sau al regulilor de acces și desfășurare a evenimentului stabilit de organizator pentru evenimentul respectiv.
            </p>

            <h1>6. Politica de retur</h1>
            <h3>Dreptul de retragere </h3>
            <p>În conformitate cu Ordonanța de urgență nr. 34/2014 („OUG 34/2014”) privind drepturile consumatorilor în cadrul contractelor încheiate cu profesioniștii, precum și pentru modificarea și completarea unor acte normative, consumatorul beneficiază de o perioadă de 14 zile pentru a se retrage dintr-un contract la distanță sau dintr-un contract în afara spațiilor comerciale, fără a fi nevoit să justifice decizia de retragere și fără a suporta alte costuri decât costurile directe legate de returnarea produselor și eventualele prejudicii provocate de diminuarea valorii produselor rezultată din manipularea acestora.
            </p>
            <p>De la regula generală instituită potrivit celor de mai sus, OUG 34/2014 reglementează următoarele excepții relevante pentru Utilizatorii uNite:
            </p>
            <ul>
                <li>Achiziționarea de biletele la spectacole este exceptată de la aplicarea dreptului de retragere reglementat de OUG 34/2014, în conformitate cu art. 16 lit. i) din acest act normativ.
                </li>
                <li>Contractele de prestări de servicii sunt exceptate de la dreptul de retragere, după prestarea completă a serviciilor, dacă executarea a început cu acordul prealabil expres al consumatorului și după ce acesta a confirmat că a luat cunoștință de faptul că își va pierde dreptul la retragere după executarea completă a contractului de către profesionist, în conformitate cu art. 16 lit. a) din OUG 34/2014.</li>
            </ul>
            <p>uNite ca și companie de ticketing (și nu de organizare evenimente) care prestează servicii de intermediere vânzare bilete în numele și pe seama Organizatorilor, nu deține biletele puse în vânzare, nu poate fi făcută responsabilă pentru returnarea banilor pe biletele cumpărate și nu este obligată în niciun fel să returneze banii pentru biletele cumpărate.</p>
            <p>Pe cale de consecință, întreaga răspundere față de Utilizatori în legătură cu evenimentele respective revine Organizatorului. Acesta își asumă integral răspunderea față de Utilizatori pentru eventualele întârzieri, amânări, anulări sau pentru orice alte probleme în legătură cu evenimentele ale căror bilete au fost comercializate prin intermediul Aplicatiei mobile  și/sau Rețeaua uNite, pentru derularea acestora în condiții necorespunzătoare sau în alte condiții decât cele comunicate public de Organizator sau cele prevăzute de legislația în materie, precum și pentru orice restricții impuse cu privire la evenimente de terțe persoane implicate în organizarea lor sau de autorități. </p>
            <p>Astfel, politică de retur ce va fi aplicată pentru biletele vândute prin Aplicatia mobila și/sau Rețeaua uNite va fi cea a Organizatorului. </p>

            <h3>De ce nu îmi este returnat comisionul de emitere bilete?</h3>
            <p>Achiziţionarea de servicii de intermediere pentru vânzarea de bilete, concomitent cu achiziția de bilete prin intermediul Aplicatiei mobile și/sau Rețeaua uNite de către Utilizatori, sunt exceptate de la aplicarea dreptului de retragere reglementat de OUG 34/2014, în conformitate cu art. 16 lit. A) din actul normativ menţionat. Costul de emitere bilete este afișat separat de contravaloarea biletelor încă din momentul selecției categoriei de bilete dorite și înainte de a plasa și a achita o comandă.
            </p>
            <p>Utilizatorul este de acord în mod expres cu începerea executarii serviciilor de intermediere  la plasarea unei comenzi ferme. Utilizatorul declară că înţelege că prin finalizarea comenzii îşi va pierde dreptul de retragere, ca urmare a executării complete a contractului de către uNite.
                Astfel, în momentul în care ați plasat o comandă și ați achitat biletele, dumneavoastră, în calitate de Utilizator, ați consumat și ați beneficiat în întregime de acest serviciu de intermediere, distinct de bilete achiziționate, drept urmare ați achitat un comision pentru serviciul prestat de firma noastră.
            </p>
            <p>Prețul serviciilor de intermediere nu se returnează, indiferent dacă solicitați retur sau evenimentul se amână / anulează, dat fiind că acest serviciu a fost finalizat cu succes la momentul achizitiei biletului și nu este condiționat de desfășurarea efectivă a evenimentului. </p>

            <h3>Refuz de plată (chargeback)
            </h3>
            <p>În cazul inițierii unui refuz de plată (chargeback) pentru o comandă plasată pe Aplicatia mobila și/sau în Rețeaua uNite, Utilizatorul declară și înțelege că prin finalizarea comenzii își va pierde dreptul de a primi suma disputată pentru contravaloarea comisionului de emitere bilete și/sau taxele pentru metodele de plată și/sau livrare. </p>

          <h3>Evenimente anulate / amanate </h3>
            <p>În cazul nefericit al anulării / amânării unui eveniment, banii se vor returna numai conform voinței Organizatorului. Dacă părțile nu convin altfel în scris, returnarea banilor revine în mod exclusiv Organizatorului. </p>
            <p>Sumele încasate aferente biletelor vândute sunt decontate la intervale precise în conturile Organizatorilor, conform înțelegerilor contractuale avute cu aceștia. Prin urmare, în cazul anulării unui eveniment, uNite va depune toate eforturile pentru a determina Organizatorii să returneze banii Utilizatorilor, neputând să utilizeze alte căi exceptând căile legale, și nu oferă nicio garanţie în acest sens şi nu are nicio obligaţie de a despăgubi Utilizatorul în lipsa unei înţelegeri prealabile cu Organizatorul. Orice acțiuni legale inițiate de către Utilizatori vor fi luate asupra Organizatorului, nu asupra distribuitorului, în speță, uNite.
            </p>
            <p>Numai în cazul în care Organizatorul și uNite au convenit în scris returnarea contravalorii biletelor de către uNite, returul se face cel mai devreme după 7 zile după anunțul oficial de anulare a evenimentului.  uNite va emite un comunicat prin canalele de comunicare specifice prin care va anunța Utilizatorii despre metodele specifice de retur ale biletelor și metodele de rambursare ale contravalorii biletelor în funcție de metoda de plată aleasă de Utilizator în momentul achiziției biletelor. În cazul în care Organizatorul nu returnează sumele aferente comenzilor solicitate pentru returnare către uNite, uNite nu va putea efectua plățile către Utilizatori. </p>
            <p>După returnare, biletele respective sunt invalidate automat. Biletele deteriorate sau care nu au fost achiziționate prin intermediul Aplicatiei mobile și/sau Rețeaua uNite nu vor fi acceptate pentru retur și rambursare a contravalorii acestora.
            </p>
            <p>Pentru a înlătura orice îndoială, uNite şi Organizatorul precizează că nu va fi returnat decât contravaloarea biletului (fără alte taxe, tarife, costuri, cheltuieli suportate de cumpărător cu ocazia cumpărării acestuia, inclusiv, dar fără a se limita la tarif curier, taxa de rezervare, dacă e cazul etc).
            </p>

            <h3>
                Returnarea contravalorii biletelor
            </h3>
            <p>Numai dacă Organizatorul este de acord cu returul biletelor în urma solicitării scrise primite pe adresa de contact, rambursarea contravalorii biletelor se va face în funcție de modalitatea de plată cu care s-au achiziționat biletele:</p>
            <ul>
                <li>dacă biletele au fost achiziționate cu plata prin card sau Paypal rambursarea sumei ce reprezintă contravaloarea biletelor se va efectua automat în contul din care s-a făcut plata inițială; Procesatorul de plăți cu care lucrează uNite, poate returna automat doar comenzile făcute cu maxim 6 luni înainte, în caz contrar va trebui să ne oferiți pe emailul de suport un IBAN din România în care se vor transfera banii.  uNite nu va returna contravaloarea biletelor în conturi înregistrate la bănci din alte țări sau în conturi de Revolut.
                </li>
                <li>dacă biletele au fost achiziționate cu plata prin OP sau de la unul din Partenerii Autorizați din Rețeaua uNite, rambursarea sumei ce reprezintă contravaloarea biletelor se va face doar într-un cont bancar în lei (titular de cont fiind persoana fizica) comunicat pe emailul de suport. Partenerii Autorizați din Rețeaua uNite nu au obligația returnării sumelor achitate de Utilizator. uNite nu va returna contravaloarea biletelor în conturi înregistrate la bănci din alte țări sau în conturi de Revolut.
                </li>
            </ul>

            <h3>Modificări, anulări, returnări, deficiențe organizatorice
            </h3>
            <p>uNite nu își asumă răspunderea pentru eventualele întârzieri, amânări, anulări sau deficiențe de organizare ale evenimentului pentru care au fost cumpărate biletele sau pentru restricționări impuse de Organizator sau de instituții ale statului (privind numărul de bilete ce pot fi cumpărate de o persoana sau persoane individuale supuse unor restricționări legale, alte restricționări), cumpărătorii trebuind să se adreseze Organizatorului pentru informații suplimentare.
            </p>
            <p>uNite nu răspunde pentru nicio pretenţie în legătură cu: intensitatea sau calitatea sunetului, vizibilitatea, aranjarea meselor în local, setlistul ales de artist, serviciile de curăţenie, alimentaţie, pază, garderobă, baruri, dar fără a se limita la acestea. Pentru orice plângere referitoare la aspectele menţionate mai sau la orice alte aspecte care ţin de organizarea evenimentului, vă rugăm să vă adresaţi Organizatorului.</p>
            <p>Organizatorul are dreptul să modifice datele de desfășurare a evenimentului, noile informații fiind anunțate prin mijloace de comunicare specifice organizatorului.</p>
            <p>În cazul anulării, amânării sau modificărilor majore de program sau distribuție, uNite își rezerva dreptul de a informa cumpărătorii de bilete în legătură cu aceste modificări. Această informare este un serviciu voluntar al uNite și se poate realiza prin poștă sau pe cale electronică, prin e-mail, sms, social media sau pe aplicatie.
            </p>
            <p>În caz de pierdere sau de furt al biletelor online, utilizatorul cumpărător este obligat să notifice acest fapt către Organizator, în vederea înlocuirii biletelor. În cazul în care utilizatorul nu procedează la notificarea organizatorului în timp util, Organizatorul sau uNite nu își asumă nicio răspundere pentru utilizarea biletelor respective de o alta persoana, nici pentru imposibilitatea cumpărătorului inițial de a mai participa la evenimentul respectiv pe baza biletelor pierdute / furate.
            </p>
            <p>Sub nicio formă biletele nu se mai pot returna după ce evenimentul a avut loc.
            </p>

            <h3>
                7. Serviciul de Relații cu clienții
            </h3>
            <p>uNite asigura serviciul de relații cu clienții în intervalul programului de lucru, L-V 11.00 - 19.00. </p>
            <p>Nu ezitați sa ne contactați și în afara programului de lucru, vom încerca sa va oferim suport cat mai rapid cu putință.</p>
            <p>Cererile ferme de suport se realizează doar pe email, fiind alocat un număr de înregistrare în sistemul folosit pentru serviciul cu clienții, pentru a putea urmări discuțiile și a putea rezolva cat mai rapid cererea înregistrată. Formularea unui răspuns poate dura pana la 48h în funcție de complexitatea cererii.</p>

            <h3>8. Modalități de plată
            </h3>
            <p>Pentru cumpărarea de bilete, Serviciul uNite acceptă următoarele modalități de plată:
            </p>
            <p>a) Plata cu cardul bancar
                Plățile prin intermediul cardurilor de debit sau credit sunt procesate de Stripe (Stripe Payments UK Ltd), partenerii uNite în procesarea acestor plăți. Comunicația între client și procesatorul de plăți se face pe o pagina de plată prin intermediul unei conexiuni securizate SSL, datele transmise fiind criptate. Toate informațiile dumneavoastră financiare vor fi prelucrate exclusiv de către procesatorul de plăți. </p>

            <h3>9. Plăți și facturare</h3>
            <p>Biletele comandate pot fi plătite cu metodele de plata pusă la dispoziție de Organizator. Comanda, implicit biletul/biletele, poate fi considerată validă numai după ce plata integrală a fost efectuată și recepționată.
            </p>
            <p>Conform OUG 28/1999, articolul 2, litera F, pentru activitățile pentru care încasările se realizează pe baza de bonuri cu valoare fixă tipărite conform legii - bilete de acces la spectacole și altele similare - nu se eliberează bon fiscal.
            </p>
            <p>Pentru comenzile efectuate în numele unei entități juridice se pot emite facturi fiscale  pentru contravaloarea biletelor în urma unei solicitări scrise pe adresa tcomboeanu@gmail.com. Solicitarea trebuie să conțină toate datele complete și corecte: numărul de comandă și datele de facturare complete (nume societate, CUI, Nr. Reg Comert, Sediu Social, delegat). Factura nu este emisă de uNite, ci de către organizatorul evenimentului în numele căruia noi vindem bilete. Primirea facturii poate dura de la câteva zile la o lună sau chiar mai mult, în funcție de timpul de răspuns al organizatorului. </p>
            <p>Pentru fiecare comanda unde comsionul de emitere bilete este adaugat peste contravaloarea biletelor, uNite va emite către Utilizator o factură de comision cu datele pe care dvs le-ați furnizat în momentul creării contului de Utilizator. Dacă ați selectat opțiunea de logare cu Google în momentul creării contului de Utilizator, Aplicatia va prelua automat numele folosit de Utilizator in Google. În acest sens, este responsabilitatea Utilizatorului să schimbe datele contului de Utilizator cu datele reale de identificare fiscală în vederea emiterii corecte a facturii de comision. Furnizarea de informații false și utilizarea acestora în cadrul aplicatiei sau Rețelei uNite va fi raportată autorităților competente și Utilizatorul va suporta consecințele legilor în vigoare.
            </p>

            <h3>10. Prelucrarea de date cu caracter personal</h3>
            <p>uNite prelucrează datele dumneavoastră cu caracter personal cu respectarea strictă a prevederilor legale în vigoare privind prelucrarea datelor cu caracter personal și liberă circulație a acestora și cu Politica de Confidențialitate și Politica de Cookie-uri, care sunt parte integrantă din acest set de Termeni și Condiții. Vă rugăm să accesați aceste documente pentru informații complete în legătură cu prelucrarea datelor cu caracter personal de către uNite și cu modul în care vă puteți exercita drepturile cu privire la protecția datelor dumneavoastră.
                uNite vă poate trimite ulterior prin SMS sau e-mail oferte promoționale pentru alte evenimente organizate care ar putea să vă intereseze. Scopul acestor mesaje este de fidelizare a clienților. Dacă nu doriți să mai primiți aceste informări, vă puteți dezabona de la newsletter utilizand linkul Dezabonare din email sau puteți să ne scrieți la tcomboeanu@gmail.com pentru a va anonimiza datele pe uNite.
            </p>

            <h3>11. Proprietate Intelectuală</h3>
            <p>Materialele şi informațiile accesibile prin intermediul aplicatiei, conținutul şi elementele de grafică ale aplicatiei, inclusiv dar fără a se limita la acestea, tot conținutul în format text, precum şi sursele tehnice ale tuturor serviciilor şi facilităților prezente şi viitoare, cu excepția cazului când este menționat expres un alt proprietar, sursele paginilor dar şi orice alt material, transmis sub orice formă de şi către Utilizatori (prin vizualizare directă pe aplicatie, prin newsletter etc,) aparțin uNite şi partenerilor săi şi reprezintă conținutul aplicatiei.
            </p>
            <p>Conținutul aplicatiei, indiferent de zona în care se află în aplicatie şi indiferent de tip, poate fi utilizat exclusiv în uz personal. Orice utilizare de conținut de către terțe persoane în alte scopuri decât cel personal, se poate face numai cu acordul scris, expres şi prealabil al uNite. Astfel este interzisă copierea, preluarea, reproducerea, publicarea, transmiterea, vânzarea, distribuția parțială, integrală sau modificată a conținutului acestei aplicatie sau a oricărei părți a acestuia efectuate în alte scopuri decât cel personal, cu următoarele excepții:
            </p>

            <ul>
                <li>este permisă reproducerea (pe aplicatiile noncomerciale, forumuri, articole din presă etc.) unor mici fragmente din articolele publicate (max. 2 paragrafe). Specificarea sursei informațiilor preluate este obligatorie, sub următoarea formă: (Sursă: uNite)</li>
                <li>sunt permise link-urile către aplicatie, iar specificarea sursei informațiilor se va face după fiecare link sau în finalul articolului, după cum urmează: 'Informații furnizate prin amabilitatea uNite'.
                </li>
            </ul>
            <p>uNite își rezervă dreptul de a acționa în instanță orice persoană şi /sau entitate care încalcă în orice mod prevederile de mai sus. Cererile de utilizare a conținutului aplicatie în orice scop altul decât cel personal pot fi făcute la adresa de e-mail: tcomboeanu@gmail.com. Orice persoană care expediază în orice mod informații ori materiale către aplicatie îşi asumă obligația de a nu prejudicia în nici un mod drepturile de autor pe care o terţă persoană le-ar putea invocă în legătură cu materialele şi informațiile transmise în orice mod către aplicatie, iar persoanele care expediază în orice mod informații ori materiale înțeleg şi acceptă că încălcarea în orice mod a acestei obligații nu poate angaja în nici un mod răspunderea uNite, ci numai răspunderea persoanelor respective.
            </p>

            <h3> 12. Condiții specifice</h3>
            <p>10.1 Traficul de date asociat utilizării aplicatiei se taxează conform planului tarifar al operatorului de telefonie mobilă al Utilizatorului.</p>
            <p>10.2 uNite poate limita în timp real numărul de bilete disponibile la un anumit eveniment atunci când cererea (globală sau locală) este foarte mare pentru a proteja platforma de supraîncărcare si a va asigura o experienta de cumparare cat mai placuta. Pe măsură ce traficul scade, biletele se vor repune in vanzare, treptat. Aceste practici sunt comune așa că uneori s-ar putea sa observați tarife pe cale de epuizare urmand ca în scurt timp alte bilete să apară la vanzare.</p>

            <h3>13. Limitarea răspunderii </h3>
            <p>uNite nu răspunde sub nicio formă, în nicio măsură şi nu poartă niciun fel de responsabilitate pentru serviciul de procesare a plăților cu cardul.</p>
            <p>uNite nu este responsabilă pentru niciun fel de eroare şi/sau fraudă decurgând din, sau în legătură cu acest serviciu. Totuși, deși serviciile de plată sunt oferite şi dirijate în totalitate şi în mod independent de către furnizorii noștri, uNite va întreprinde toate măsurile rezonabile pentru a limita pe cât posibil riscul apariției acestor erori şi/sau fraude.</p>
            <p>uNite nu îşi asumă obligația şi nu garantează implicit sau expres, pentru conținutul oferit de partenerii săi sau de Utilizatorii aplicatiei. uNite va face toate eforturile rezonabile pentru a asigura acuratețea şi încrederea în aplicatie şi va încerca să corecteze erorile şi omisiunile cât mai repede posibil. Cu toate acestea, uNite nu este responsabilă pentru inadvertențe, erori sau omisiuni în cadrul informațiilor furnizate de Utilizatori.</p>
            <p>În mod expres, Utilizatorii aplicatiei sunt de acord să exonereze de răspundere pe uNite pentru orice acțiune judiciară sau extrajudiciară, care provine că urmare a utilizării incorecte sau frauduloase a aplicatiei.
            </p>
            <p>În cazul apariției unui caz de forţă majoră, uNite şi/sau operatorii, directorii, angajații, sucursalele, filialele și reprezentanții săi, sunt exonerați total de răspundere. Cazurile de forţă majoră includ, dar nu se limitează la, erori de funcționare ale echipamentului tehnic al uNite, lipsa funcționării conexiunii la internet, lipsa funcționării conexiunilor de telefon, virușii informatici, accesul neautorizat în sistemele aplicatiei, erorile de operare, greva, război etc.</p>
            <p>Utilizatorii renunță la cereri, pretenții, acțiuni, impuneri, pierderi, daune, costuri (incluzând, fără nici un fel de limitare, onorariile avocaţilor), cheltuieli, judecați, decizii, amenzi, regularizări sau alte obligații rezultate sau relaţionate cu orice altă acțiune a Utilizatorului în legătură cu utilizarea Serviciului uNite sau orice alt aspect relaţionat Serviciului uNite.
            </p>

            <h3>14. Modificarea Termenilor şi Condițiilor</h3>
            <p>uNite are dreptul de a modifica unilateral, în orice moment, Termenii şi Condițiile pentru Utilizatori, precum și orice alte politici relevante. Utilizarea aplicatiei în orice fel sau a Serviciului uNite, ulterior modificării Termenilor și Condițiilor, reprezintă acceptul Utilizatorului cu privire la modificările efectuate. Termenii și Condițiile modificate vor fi aplicabile oricărei comenzi/tranzacții, începând cu ziua modificării acestora.
            </p>

            <h3>15. Legea aplicabilă. Litigii</h3>
            <p>Drepturile şi obligaţiile Utilizatorilor şi ale uNite, prevăzute de Termeni şi Condiţii pentru Utilizatori, precum şi toate efectele juridice pe care le produc Termenii şi Condiţiile vor fi interpretate în conformitate cu şi guvernate de legea română în vigoare. </p>
            <p>Orice litigiu născut din sau în legătură cu Termenii şi Condiţiile va fi soluţionat pe cale amiabilă. În caz de imposibilitate de a ajunge la un acord, litigiul va fi soluţionat de către instanţa judecătorească română competentă aflată în rază teritorială a municipiului Bucureşti.
            </p>
        </div>
    );
};

export default Terms;
