import React,  {useEffect} from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { useParams } from 'react-router-dom';

import PrivacyPolicy from './Pages/Privacy-policy';
import Home from "./Pages/Home/Home";
import Terms from "./Pages/Terms";
import Admin from "./Pages/Admin/Admin";

const App = () => {
    const subdomain = window.location.hostname.split('.')[0];
    useEffect(() => {
        ReactGA.initialize('G-FTTR648LBC');
        ReactGA.send('pageview');
    }, []);

    if(subdomain === 'admin') {
        return <Admin />;
    }

    function RedirectToApp() {
        const { eventId } = useParams();
        const appStoreUrl = "https://onelink.to/9aw52g";

        useEffect(() => {
            if(eventId === 'web3-garden-gala') {
                const userAgent = navigator.userAgent || navigator.vendor;
                const timestamp = Date.now();
                const deepLinkUrl = `uniteapp://events/2tEFrCBPsGVreGu1IMeq?timestamp=${timestamp}`;

                if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) {
                    window.location.replace(deepLinkUrl);
                }
                return;
            }
            if(eventId === 'melancolia-hip-hop') {
                const userAgent = navigator.userAgent || navigator.vendor;
                const timestamp = Date.now();
                const deepLinkUrl = `uniteapp://events/ij8bUlVSBPSm8TFabAm2?timestamp=${timestamp}`;

                if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) {
                    window.location.replace(deepLinkUrl);
                }
                return;
            }
            if (eventId) {
                const userAgent = navigator.userAgent || navigator.vendor;
                const timestamp = Date.now();
                const deepLinkUrl = `uniteapp://events/${eventId}?timestamp=${timestamp}`;

                if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) {
                    window.location.replace(deepLinkUrl);
                }
            }
        }, [eventId]);

        return <Home />;
    }



    return (
        <Router>
            <div className="app-container">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/events/:eventId" element={<RedirectToApp />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
