import React from 'react';
import ReactGA from 'react-ga4';
import unite from '../images/logo_app_header.png';
import downloadAppStoreBlack from '../images/download_app_logo_black.svg'
import downloadGoogle from '../images/google-play-badge.svg'
import instagram from '../images/insta.png'
import facebook from '../images/facebook.png'
import './Home.css';

function Home() {
    const iosAppLink = 'https://apps.apple.com/ro/app/u-nite/id6448663066';
    const androidAppLink = 'https://play.google.com/store/apps/details?id=com.tasecombo.unite';
    const instaLink = 'https://www.instagram.com/u.nite.events/';
    const facebookLink = 'https://www.facebook.com/profile.php?id=100092468147091';

    const handleClick = (linkName) => {
        ReactGA.event({
            category: 'User',
            action: `Clicked on ${linkName}`,
        });
    };

    return (
        <div className="App">
            <div className="app-header">
                <div className="logo-container">
                    <img src={unite} alt="logo" className="logo-name" />
                    <p className="sub-logo-text">We plan, you party!</p>
                </div>
                {/*<img src={logo} alt="logo" className="logo" />*/}
                <div className="download-logo-container">
                    <a
                        href={iosAppLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => handleClick('iOS App')}
                    >
                        <img src={downloadAppStoreBlack} alt="logo" className="download-logo" />
                    </a>
                    <a
                        href={androidAppLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => handleClick('Android App')}
                    >
                        <img src={downloadGoogle} alt="logo" className="download-logo" />
                    </a>
                </div>
                <div style={{marginTop: 110}}>
                    <h3>Follow us</h3>
                    <a
                        href={instaLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => handleClick('Instagram')}
                    >
                        <img src={instagram} alt="logo" className="download-logo" style={{height: 30, marginRight: 20}} />
                    </a>
                    <a
                        href={facebookLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => handleClick('Facebook')}
                    >
                        <img src={facebook} alt="logo" className="download-logo" style={{height: 30}} />
                    </a>
                </div>
            </div>
            <footer className="footer">
                <div>
                    <p>&copy; {new Date().getFullYear()} uNite. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
}

export default Home;
