import firebase from 'firebase/compat/app';

const firebaseConfig = {
    apiKey: "AIzaSyD6AlfGav93tiVG95030GBqwt1VXu7v1qo",
    authDomain: "unite-a2c54.firebaseapp.com",
    databaseURL: "https://unite-a2c54-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "unite-a2c54",
    storageBucket: "unite-a2c54.appspot.com",
    messagingSenderId: "228466062380",
    appId: "1:228466062380:web:369cf3231d931bf1f94d89",
    measurementId: "G-Z6V2Z66QVE"
};

const app = firebase.initializeApp(firebaseConfig);

export default app;